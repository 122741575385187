<script setup lang="ts">
const { establishment } = useAppConfig();
// console.log('etablissement', establishment)
</script>

<template>
    <div class="popup-contact">
        <h2>Nous contacter</h2>
        <br>
        <p>Vous rencontrez des difficultés sur {{ establishment.name }} ? Afin de répondre à vos questions, contactez-nous :</p>
        <div class="contact-cards">
            <a v-if="establishment.phone != null" :href="'tel:' + establishment.phone" class="contact-card">
                <ElementsIcon icon="fa-solid-phone" />
                <p>Par téléphone : {{ establishment.phone }}</p>
            </a>
            <a v-if="establishment.email != null" :href="'mailto:' + establishment.email" class="contact-card">
                <ElementsIcon icon="fa-solid-envelop" />
                <p>Par mail : {{ establishment.email }}</p>
            </a>
        </div>
    </div>
</template>

<style scoped>
.popup-contact h2 {
    color: var(--mainColor);
    text-align: center;
}

.popup-contact p {
    margin: 10px 0;
    color: #333;
}

.contact-cards {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
}

.contact-card {
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    gap: 10px;
    text-align: left;
    color: inherit;
    text-decoration: none;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.contact-card i {
    font-size: 24px;
    color: var(--mainColor);
}

.contact-card p {
    margin: 0;
    color: #333;
}

.contact-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

svg.iconDefault{
    color: var(--mainColor);
}

</style>