<template>
    <section :class="['p0', 'container-page', 'flex', 'bg-transparent', 'horizontalNav', 'gap1']">
        <div class="box-input">
            <SearchBar />
        </div>

        <div class="box-icon">
            <BoxCartButton :class="['btnCart', { disabled: !establishment.configuration.isCart }]"
                :link="establishment.configuration.isCart ? links.cart : ''" />

            <NuxtLink class="icon-devis" :to="quoteUrl"
                :class="{ isDisabled: !isDevis(establishment.configuration.isDevis) }"
                :aria-disabled="!isDevis(establishment.configuration.isDevis)"
                @click="!vehicleGenericData ? isSearchVehicleOpen = true : null">
                <span class="pastille" :class="{ animated: isAnimated }" v-if="sessionData?.articles?.length">{{
                    toFixedComma(totalHt, 2) }} {{ currentDevise }}</span>
                <ElementsIcon icon="calculator" />
            </NuxtLink>

            <a>
                <ElementsIcon icon="notifications" />
            </a>

            <NuxtLink :to="links.account">
                <ElementsIcon icon="user" />
            </NuxtLink>

            <a href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                <ElementsIcon icon="fa-regular-gear" />
            </a>
            <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuLink">
                <p class="gradient-text d-flex justify-content-between align-items-center column-gap-5">
                    <span>Mes paramètres</span>
                    <ElementsIcon icon="xmark" />
                </p>
                <hr>
                <NuxtLink class="dropdown-item" :to="links.accountSubscription">Mon abonnement</NuxtLink>
                <NuxtLink class="isDisabled dropdown-item">Mes mécaniciens</NuxtLink>
                <NuxtLink class="isDisabled dropdown-item">Mes prestations</NuxtLink>
                <NuxtLink class="dropdown-item" :to="links.accountRate">Mes taux horaires</NuxtLink>
                <NuxtLink class="dropdown-item" :to="links.accountCustom">Mes paramètres</NuxtLink>
            </div>


            <NuxtLink v-if="establishment.email != null || establishment.phone != null" to="#"
                @click.prevent="isContactOpen = true">
                <ElementsIcon icon="fa-regular-phone" />
            </NuxtLink>

            <Modal :isOpen="isContactOpen" @close="isContactOpen = false">
                <template #content>
                    <ModalContact />
                </template>
            </Modal>

        </div>
    </section>
</template>


<script setup lang="ts">
import { ref } from 'vue';
import SearchBar from '~/components/Elements/SearchBar.vue';
import { useStorage } from '@vueuse/core';
import type { Quote, VehicleGenericInformations } from '~/types/interfaces';
import { useLicenceStore } from "~/store/useLicenceStore";
import { EstimateContentType } from "~/types/datas";
import Modal from "@/components/Elements/Modal.vue";
import ModalContact from "@/components/modals/ModalsContact.vue";

const { toFixedComma } = useFormatNumber();
const config = useRuntimeConfig();
const { isSearchVehicleOpen } = useDashboard();
const { currentDevise } = useCart();
const { establishment, links } = useAppConfig();
const vehicleGenericData: Ref<VehicleGenericInformations> = useCookie(config.public.cookies.vehicleGenericInformations);
const isAnimated = ref<boolean>(false);
const sessionData = useStorage<Quote>("quote", <Quote>{}, sessionStorage);
const { isDevis } = useLicenceStore();

const quoteUrl = computed(() => !isDevis(establishment.configuration.isDevis) ? '' : sessionData.value?.id ? `${links.devisEdit}${sessionData.value.id}?refresh=false` : links.devis);

const worksTotalHt = computed(() => {
    let total = 0;
    if (!sessionData.value.articles) return total;
    sessionData.value.articles.forEach(product => {
        product.works?.forEach(work => {
            total += (work.calculatedTime !== '' ? work.calculatedTime : 0) * work.rate.value;
        });
        if (product.productTypeQuoteId === EstimateContentType.variousTimes && product.rate) {
            total += product.rate.value * product.quantity * (1 - product.price.discount / 100);
        }
        if (product.productTypeQuoteId === EstimateContentType.laborTime && product.rate) {
            total += product.rate.value * product.quantity * (1 - product.price.discount / 100);
        }
    });
    return total;
});

const partsTotalHt = computed(() => {
    let total = 0;
    if (!sessionData.value.articles) return total;
    sessionData.value.articles.forEach(product => {
        if ((product.productTypeQuoteId !== EstimateContentType.variousTimes) && (product.productTypeQuoteId !== EstimateContentType.laborTime)) {
            total += product.price.unitPriceBeforePromoHT * product.quantity * (product.conditionnement ?? 1) * (1 - product.price.discount / 100);
        }
    });
    return total;
});

const totalHt = computed(() => partsTotalHt.value + worksTotalHt.value);

watch(totalHt, () => {
    isAnimated.value = true;
    setTimeout(() => { isAnimated.value = false }, 1000);
});

const isContactOpen = ref(false);

interface Props {
    isContactOpen: boolean;
}
withDefaults(defineProps<Props>(), {
    isContactOpen: () => false
});
defineEmits(['close']);

</script>


<style lang="scss" scoped>
.horizontalNav {
    padding: 0;

    & .iconDefault {
        --icon-size-default: 25px;
        color: var(--secondaryColor);
    }

}

.box-input {
    background: white;
    flex: 10;
    padding: 15px 30px;
    border-radius: 10px;
    display: flex;
    gap: 1rem;
}

.box-icon {
    background: white;
    flex: 1;
    padding: 10px 30px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 2rem;
}

.box-icon a svg {

    transition: 0.7s all;
}

.box-icon a:hover svg {
    transform: translateY(-5px);
}

.box-icon i span {
    font-size: 10px;
}

.box-icon i {
    transition: .7s all;
}

.box-icon i:hover {
    transform: translateY(-5px);
}

.box-icon .fa-user {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.box-icon .fa-user:after {
    content: "Mon compte";
    font-size: 13px;
    text-transform: lowercase;
}

.box-icon a {
    text-decoration: none;
    color: rgb(var(--mainText));
    font-size: 25px;
    height: 38px;
}

.dropdown-menu.show {
    transform: translate(-104px, 76px) !important;
    padding: 1.25rem 1.5rem;
}

.dropdown-item {
    font-family: Inter;
    font-size: 1rem !important;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
}

.gradient-text {
    /*font-weight: 600;*/
    color: var(--mainColor)
}

input,
input::placeholder {
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    /* 150% */
    letter-spacing: -0.01rem;
    border-radius: 0.625rem;
}

input::placeholder {
    color: var(--neutral71);
    font-size: 14px;
}

.btn-search {
    border-radius: 0.625rem;
}

.btnCart:deep(.buttonCart__number) {
    font-size: .825rem;
    transform: translate(34%, 27%);
    background-color: var(--mainColor);
}

.btnCart.disabled:deep(svg) {
    color: var(--neutral71);
}

.icon-devis {
    position: relative;

}

.icon-devis:hover {
    cursor: pointer;
}

.icon-devis .pastille {
    position: absolute;
    top: -20%;
    right: -20%;
    transform: translate(70%, 28%);
    background-color: red;
    padding: 0.1rem;
    border-radius: 7%;
    min-width: 1rem;
    height: 0rem;
    min-height: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--defaultFontColor);
    font-size: 0.8rem;
    z-index: 1;
    white-space: nowrap;
}

@media screen and (max-width: 1200px) {
    .horizontalNav {
        flex-direction: column-reverse;
    }

    .box-icon {
        justify-content: space-between;
    }
}

@media screen and (max-width: 1000px) {
    .horizontalNav {
        flex-direction: column;
        flex: 1;
        justify-content: center;
    }

    .box-icon,
    .box-input {
        padding: 0;
    }

    .box-icon {
        justify-content: center;

    }
}

.animated {
    animation: 1s infinite scaleUp;
}

@keyframes scaleUp {
    0% {
        transform: translate(70%, 28%) scale(1);
    }

    50% {
        transform: translate(70%, 28%) scale(2);

    }

    100% {
        transform: translate(70%, 28%) scale(1);
    }
}
</style>
